import { twMerge as cn } from "tailwind-merge";
import { EncryptStorage } from "encrypt-storage";
import CryptoJS from "crypto-js";
// secret
const encryptStorage = new EncryptStorage(import.meta.env.VITE_STORAGE, {
  storageType: "localStorage",
  encAlgorithm: "Rabbit",
});

export const URL_API = import.meta.env.VITE_URL_SSO;
export const isEmpty = (variable) => [undefined, null, ""].includes(variable);
function encryptData(value) {
  const key = import.meta.env.VITE_STORAGE;
  const encrypted = CryptoJS.AES.encrypt(value, key).toString();
  return encodeURIComponent(encrypted);
}

function decryptData(value) {
  const key = import.meta.env.VITE_STORAGE;
  const decodedValue = decodeURIComponent(value);
  const decrypted = CryptoJS.AES.decrypt(decodedValue, key).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted || null;
}

export { cn, encryptStorage, encryptData, decryptData };
